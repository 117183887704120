import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  findApp,
  rejectApp,
  approveApp,
  followupApp
} from "../../actions/dashboard";
import {
  createNotification,
  pageTitle,
  isChasier,
  currency
} from "../../utils/helpers";
import { IoMdQrScanner, IoIosClose } from "react-icons/io";
import DocumentException from "../common/DocumentException";
import Spinner from "../../components/ui/Spinner";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import QrReader from "react-qr-reader";
import TermsAgreement from "../common/TermsAgreement";
import { isEmpty, has } from "lodash";
import Logo from "../../img/logo.png"
import classnames from "classnames"

const MySwal = withReactContent(Swal);

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sn: "",
      loading: false,
      qrcodeShow: false,
      result: null,
      agree: false,
      confirmApprove: false
    };
  }

  async componentDidMount() { }

  onKeyFind(e) {
    if (e.charCode === 13) {
      this.findApplication();
    }
  }

  onShowQr(e) {
    this.setState({
      qrcodeShow: true,
      result: false
    });
  }
  onCloseQr(e) {
    this.setState({
      qrcodeShow: false,
      result: true
    });
  }

  onApprove(e) {
    if (this.state.agree === false) {
      MySwal.fire({
        type: "warning",
        title: "Please tick and read agreements"
      });
      return;
    }
    MySwal.fire({
      title: "Are you sure want to approve this application?",
      text: "This button will change status to approved",
      showCancelButton: true
    }).then(prompt => {
      if (has(prompt, 'value')) {
        MySwal.fire({
          title: <p>Loading...</p>,
          onOpen: () => {
            MySwal.showLoading();
            this.props
              .approveApp(this.state.sn)
              .then(res => {
                MySwal.fire({
                  type: "success",
                  title: "Great!",
                  text: "Application has been approved",
                  timer: 2000
                });
                this.makeDefaultState();
              })
              .catch(err => {

                MySwal.fire({
                  type: "error",
                  title: "Error Exception!",
                  text: has(err, "data")
                    ? err.data.messages
                    : "Something went wrong"
                });
                this.makeDefaultState();
              });
          }
        });
      }
    });
  }

  onReject(e) {
    MySwal.fire({
      title: "Are you sure want to reject this application?",
      text: "This button will change status to rejected",
      showCancelButton: true
    }).then(prompt => {
      if (has(prompt, 'value')) {
        MySwal.fire({
          title: <p>Loading...</p>,
          onOpen: () => {
            MySwal.showLoading();
            this.props
              .rejectApp(this.state.sn)
              .then(res => {
                MySwal.fire({
                  type: "success",
                  title: "Ooo Sorry! Rejected",
                  text: "This application has been rejected",
                  timer: 2000
                });
                this.makeDefaultState();
              })
              .catch(err => {
                console.log(err);
                MySwal.fire({
                  type: "error",
                  title: "Error Exception!",
                  text: has(err, "data")
                    ? err.data.messages
                    : "Something went wrong"
                });
                this.makeDefaultState();
              });
          }
        });
      }
    });
  }

  onFollowup(e) {
    MySwal.fire({
      title: "Are you sure want to follow-up this application?",
      text: "This button will change status to followup",
      showCancelButton: true
    }).then(prompt => {
      if (has(prompt, 'value')) {
        MySwal.fire({
          title: <p>Loading...</p>,
          onOpen: () => {
            MySwal.showLoading();
            this.props
              .followupApp(this.state.sn)
              .then(res => {
                MySwal.fire({
                  type: "success",
                  title: "Followed-up",
                  text: "Your application has been followed-up",
                  timer: 2000
                });
                this.makeDefaultState();
              })
              .catch(err => {
                MySwal.fire({
                  type: "error",
                  title: "Error Exception!",
                  text: has(err, "data")
                    ? err.data.messages
                    : "Something went wrong"
                });
                this.makeDefaultState();
              });
          }
        });
      }
    });
  }

  makeDefaultState() {
    this.setState({
      loading: false,
      result: false,
      qrcodeShow: false,
      agree: false,
      sn: "",
      confirmApprove: false
    });
  }

  findApplication() {
    this.setState({
      loading: true,
      result: false
    });
    this.props.findApp(this.state.sn).then(res => {
      this.setState({
        loading: false,
        result: true,
        qrcodeShow: false
      });
    }).catch(err=>{
      this.setState({
        loading: false,
        result: false,
        qrcodeShow: false
      })
      createNotification("error", has(err, 'data.messages') ? err.data.messages : "Something went wrong!");
    });
  }

  handleScan = data => {
    if (data) {
      this.setState(
        {
          sn: data,
          qrcodeShow: false
        },
        () => {
          this.findApplication();
        }
      );
    }
  };
  handleError = err => {
    console.error(err);
  };

  render() {
    const { user } = this.props.auth.user;
    const { appli } = this.props.dashboard;

    return (
      <DocumentException
        title={pageTitle("Dashboard")}
        className="dashboardPage"
        history={this.props.history}
        url={this.props.location.pathname}
      >
        <div className="container">
          <div className="row justify-content-md-center mt-3">
            <div className="col-sm-12 col-lg-8 mb-3">
              <div className="card mb-3">

                <div className="card-body">
                  <React.Fragment>
                    <div className="row m-2">
                      <div className={classnames({
                        "col-9 col-xs-12": this.state.qrcodeShow === false,
                        "col-12": this.state.qrcodeShow === true
                      })}>
                        {this.state.qrcodeShow === false && (
                          <input
                            type="text"
                            name="serial_number"
                            onChange={e =>
                              this.setState({ sn: e.target.value })
                            }
                            id="serial_number"
                            className="form-control form-control-xlg"
                            placeholder="Enter serial number"
                            onKeyPress={this.onKeyFind.bind(this)}
                            value={this.state.sn}
                          />
                        )}
                        {this.state.qrcodeShow && (
                          <QrReader
                            delay={300}
                            onError={this.handleError}
                            onScan={this.handleScan}
                            style={{ width: "100%" }}
                          />
                        )}
                      </div>
                      <div
                        className={classnames({
                          "col-3 col-xs-4": this.state.qrcodeShow === false,
                          "col-3 col-xs-4 offset-xs-3 offset-4 mt-5": this.state.qrcodeShow === true
                        })}
                        style={{ paddingRight: 0 }}
                      >
                        {this.state.qrcodeShow === false && (
                          <button
                            type="button"
                            onClick={this.onShowQr.bind(this)}
                            className="btn btn-light btn-lg btn-block"
                          >
                            <IoMdQrScanner size="2em" />
                          </button>
                        )}
                        {this.state.qrcodeShow === true && (
                          <button
                            type="button"
                            onClick={this.onCloseQr.bind(this)}
                            className="btn btn-light btn-lg btn-block"
                          >
                            <IoIosClose size="2em" />
                          </button>
                        )}
                      </div>
                      <div className="clearfix" />

                      <div className="col-12" style={{ minHeight: 200 }}>
                        {!this.state.loading && isEmpty(appli) && (
                          <div style={{ textAlign: "center" }} className="mt-3">
                            <span className="text-muted">No data</span>
                          </div>
                        )}

                        <Spinner loading={this.state.loading} />
                        {this.state.result && !isEmpty(appli) && (
                          <div className="mt-4">
                            <table className="table table-bordered table-striped">
                              <tbody>
                                <tr>
                                  <th>Email Address</th>
                                  <td>{appli.app.customer.email}</td>
                                </tr>
                                <tr>
                                  <th>Phone No.</th>
                                  <td>{appli.app.phone_no}</td>
                                </tr>
                                <tr>
                                  <th>Nationality</th>
                                  <td>{appli.app.nationality}</td>
                                </tr>
                                <tr>
                                  <th>Loan Amount</th>
                                  <td>{currency(appli.app.loan_amount)}</td>
                                </tr>
                                <tr>
                                  <th>Monthly Income</th>
                                  <td>{currency(appli.app.monthly_income)}</td>
                                </tr>
                                <tr>
                                  <th>Date Submit</th>
                                  <td>{appli.app.created_date.long}</td>
                                </tr>
                                {appli.action && 
                                <tr>
                                  <th>Status</th>
                                  <td>
                                    {appli.action.status == 'followup' && 
                                      <>
                                        <span className="badge badge-info">Follow-up</span> <br/>
                                        <span>{appli.action.followedup_date.long}</span>
                                      </>
                                    }
                                    {appli.action.status == 'rejected' && 
                                      <>
                                        <span className="badge badge-secondary">Rejected</span> <br/>
                                        <span>{appli.action.rejected_date.long}</span>
                                      </>
                                    }
                                    {appli.action.status == 'approved' && 
                                      <>
                                        <span className="badge badge-success">Approved</span> <br/>
                                        <span>{appli.action.approved_date.long}</span>
                                      </>
                                    }
                                  </td>
                                </tr>}

                                
                              </tbody>
                            </table>
                            {appli.closed === false && <React.Fragment>
                              {this.state.confirmApprove && (
                                <div className="mt-2 mb-2">
                                  <div
                                    className="mt-3 mb-3"
                                    style={{
                                      overflowY: "scroll",
                                      height: 180,
                                      padding: 15,
                                      border: "1px solid #eee"
                                    }}
                                  >
                                    <h5>Terms & Agreements</h5>
                                    <TermsAgreement />
                                  </div>

                                  <div className="mt-2 mb-4 custom-control custom-checkbox">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id="customCheck1"
                                      value={this.state.agree}
                                      onChange={e =>
                                        this.setState({
                                          agree: !this.state.agree
                                        })
                                      }
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="customCheck1"
                                    >
                                      I Agree with terms and conditions
                                    </label>
                                  </div>

                                  <button
                                    type="button"
                                    className="btn btn-dark btn-lg mr-2"
                                    onClick={this.onApprove.bind(this)}
                                  >
                                    Yes, I Agree & Submit
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-outline-dark btn-lg mr-2"
                                    onClick={(e) => this.setState({ confirmApprove: false })}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              )}
                              {this.state.confirmApprove === false && (<div>
                                {(appli.action == null) && <div
                                  className="mt-2"
                                  style={{ marginBottom: 20 }}
                                >
                                  <button
                                    type="button"
                                    className="btn btn-dark btn-lg mr-2"
                                    onClick={e =>
                                      this.setState({ confirmApprove: true })
                                    }
                                  >
                                    Approve
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-outline-danger mr-2 btn-lg"
                                    onClick={this.onReject.bind(this)}
                                  >
                                    Reject
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-outline-info btn-lg"
                                    onClick={this.onFollowup.bind(this)}
                                  >
                                    Follow up
                                  </button>
                                </div>}

                                {(appli.action && appli.action.isOpen === true) && <div
                                  className="mt-2"
                                  style={{ marginBottom: 20 }}
                                >
                                  <button
                                    type="button"
                                    className="btn btn-dark btn-lg mr-2"
                                    onClick={e =>
                                      this.setState({ confirmApprove: true })
                                    }
                                  >
                                    Approve
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-outline-danger mr-2 btn-lg"
                                    onClick={this.onReject.bind(this)}
                                  >
                                    Reject
                                  </button>
                                 
                                </div>}
                              </div>)}
                            </React.Fragment>}
                          </div>
                        )}
                      </div>
                    </div>
                  </React.Fragment>
                </div>
              </div>
              <p align="center">This Account for {user.name}</p>
            </div>
          </div>
        </div>
      </DocumentException>
    );
  }
}

Dashboard.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStatetoProps = state => ({
  auth: state.auth,
  user: state.auth.user.user,
  dashboard: state.dashboard
});

export default connect(
  mapStatetoProps,
  { findApp, approveApp, rejectApp, followupApp }
)(Dashboard);
