import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import { changePassword } from "../../actions/user";
import { logoutUser, clearCurrentProfile } from "../../actions/auth";
import {
  createNotification,
  pageTitle,
  encapsulateErrors
} from "../../utils/helpers";
import { Cardbox } from "../ui";
import { changePasswordValidate } from "../../validations/user";
import DocumentException from "../common/DocumentException";
import _ from "lodash";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      old_password: "",
      password: "",
      confirm_password: "",
      errors: {}
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  async onSubmit(e) {
    e.preventDefault();
    let userData = {
      old_password: this.state.old_password,
      new_password: this.state.password,
      new_password_confirmation: this.state.confirm_password
    };

    try {
      await changePasswordValidate(userData);
    } catch (err) {
      this.setState({ errors: err });
      createNotification("error", _.join(_.toArray(err), "\n"));
      return false;
    }

    this.props
      .changePassword(userData)
      .then(res => {
        createNotification("success", res.messages);
        this.props.clearCurrentProfile();
        this.props.logoutUser();
      })
      .catch(err => {
        this.setState({ errors: encapsulateErrors(this.props.errors.data) });
        createNotification(
          "error",
          "Failed change password! Please attention for errors"
        );
      });
  }

  render() {
    const errors = this.state.errors;

    return (
      <DocumentException
        title={pageTitle("Change Password")}
        className="changePasswordPage"
        history={this.props.history}
        url={this.props.location.pathname}
      >
        <div className="container">
          <div className="row justify-content-md-center mt-3">
            <div className="col-sm-12 col-lg-8">
              <div className="text-center mb-3">
                <h4>Change Password</h4>
              </div>

              <Cardbox className="mt-3">
                <div>
                  <div className="form-label-group mb-4">
                    <label className="font-bold" htmlFor="password">
                      Old Password
                    </label>
                    <input
                      type="password"
                      name="old_password"
                      id="old_password"
                      disabled={this.props.loader === true}
                      className={classnames("form-control form-control-md", {
                        "is-invalid": _.has(errors, "old_password")
                          ? errors.old_password
                          : false
                      })}
                      placeholder="ketik password lama"
                      onChange={this.onChange}
                      value={this.state.old_password}
                    />
                    {_.has(errors, "old_password") && errors.old_password && (
                      <div className="invalid-feedback">
                        {errors.old_password}
                      </div>
                    )}
                  </div>

                  <div className="form-label-group mb-4">
                    <label className="font-bold" htmlFor="password">
                      Password
                    </label>
                    <input
                      type="password"
                      name="password"
                      id="password"
                      disabled={this.props.loader === true}
                      className={classnames("form-control form-control-md", {
                        "is-invalid": _.has(errors, "password")
                          ? errors.password
                          : false
                      })}
                      placeholder="New Password"
                      onChange={this.onChange}
                      value={this.state.password}
                    />
                    {_.has(errors, "password") && errors.password && (
                      <div className="invalid-feedback">{errors.password}</div>
                    )}
                  </div>

                  <div className="form-label-group mb-4">
                    <label className="font-bold" htmlFor="confirm_password">
                      Confirm Password
                    </label>
                    <input
                      type="password"
                      name="confirm_password"
                      id="confirm_password"
                      disabled={this.props.loader === true}
                      className={classnames("form-control form-control-md", {
                        "is-invalid": _.has(errors, "confirm_password")
                          ? errors.confirm_password
                          : false
                      })}
                      placeholder="Confirm new password"
                      onChange={this.onChange}
                      value={this.state.confirm_password}
                    />
                    {_.has(errors, "confirm_password") &&
                      errors.confirm_password && (
                        <div className="invalid-feedback">
                          {errors.confirm_password}
                        </div>
                      )}
                  </div>
                </div>
              </Cardbox>

              <div className="form-label-group mb-4 mt-4">
                <button
                  disabled={this.props.loader === true}
                  onClick={this.onSubmit.bind(this)}
                  type="button"
                  className="btn btn-danger btn-block btn-lg"
                >
                  {this.props.loader ? "Loading..." : "Change Password"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </DocumentException>
    );
  }
}

ChangePassword.propTypes = {
  loadingRequest: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  loader: PropTypes.bool
};

const mapStatetoProps = state => ({
  errors: state.errors,
  loadingRequest: state.loadingRequest,
  loader: state.formLoader,
  user: state.auth.user.user
});

export default connect(
  mapStatetoProps,
  {
    changePassword,
    logoutUser,
    clearCurrentProfile
  }
)(ChangePassword);
